exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-behandling-js": () => import("./../../../src/pages/behandling.js" /* webpackChunkName: "component---src-pages-behandling-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-hitta-hit-js": () => import("./../../../src/pages/hitta-hit.js" /* webpackChunkName: "component---src-pages-hitta-hit-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-naprapati-js": () => import("./../../../src/pages/naprapati.js" /* webpackChunkName: "component---src-pages-naprapati-js" */),
  "component---src-pages-om-oss-js": () => import("./../../../src/pages/om-oss.js" /* webpackChunkName: "component---src-pages-om-oss-js" */),
  "component---src-pages-priser-js": () => import("./../../../src/pages/priser.js" /* webpackChunkName: "component---src-pages-priser-js" */),
  "component---src-pages-symptom-js": () => import("./../../../src/pages/symptom.js" /* webpackChunkName: "component---src-pages-symptom-js" */)
}

